<template>
  <div class="aircraft-slider">
    <Swiper
      @swiper="swiperInstance = $event"
      @slideChange="updateCurrent"
    >
      <SwiperSlide
        v-for="(image, imageId) in content?.images"
        :key="`${content?.name}-image-${imageId}`"
      >
        <BgMedia :source="image" />
      </SwiperSlide>
    </Swiper>
    <div class="panel">
      <div class="container">
        <div class="controls">
          <button
            type="button"
            class="button button_prev"
            :disabled="isStart"
            @click="swiperInstance?.slidePrev()"
            :class="{'is-hide': isLocked}"
          >
            <Icon name="back" />
          </button>
          <h3 class="title">{{content?.name}}</h3>
          <button
            type="button"
            class="button button_next"
            :disabled="isEnd"
            @click="swiperInstance?.slideNext()"
            :class="{'is-hide': isLocked}"
          >
            <Icon name="next" />
          </button>
        </div>
        <div class="info">
          <ul class="features">
            <li class="feature">
              <Icon :name="'calendar'" /> {{content?.release_year || '-'}} {{getName('mesure_year')?.secondary}}
            </li>
            <li class="feature">
              <Icon :name="'clock'" /> {{content?.hours || 0 }} {{ pluralMesure('mesure_hours', content?.hours || 0 ) }}
            </li>
            <li class="feature">
              <Icon :name="'cycle'" /> {{content?.cycles_count || 0}} {{ pluralMesure('mesure_cycle', content?.cycles_count || 0) }}
            </li>
            <li class="feature">
              <Icon :name="'person'" /> {{content?.seats}}{{content?.max_seats ? ` - ${content?.max_seats}` : ''}}
            </li>
          </ul>
          <Link
            :tag="'router-link'"
            :to="`/sale/${content?.vehicle_id}`"
          >
          <template #default>
            {{getName('more').default}}
          </template>
          <template #end>
            <Icon name="arrow" />
          </template>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import BgMedia from '../../../components/BgMedia.vue';
import Icon from '../../../components/Icon.vue';
import Link from '../../../components/Link.vue';
import observeVisibilityOnPage from '../../../tools/observeVisibilityOnPage';
import pluralFormat from '../../../tools/pluralFormat';

export default {
  components: {
    Swiper,
    SwiperSlide,
    BgMedia,
    Icon,
    Link,
  },
  name: 'AircraftSlider',
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      swiperInstance: null,
      activeIndex: 0,
      isEnd: false,
      isStart: true,
      isLocked: false,
      observer: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    currentActive() {
      return this.content[this.activeIndex];
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    updateCurrent(sw) {
      this.activeIndex = sw.activeIndex;
      this.isEnd = sw.isEnd;
      this.isStart = sw.isBeginning;
      this.isLocked = sw.isLocked;
    },
    pluralFormat,
    pluralMesure(mesure, value) {
      const types = ['default', 'secondary_multiple', 'default_multiple'];
      const newValue = Number(value);
      const needsForm = this.pluralFormat(newValue, types, this.lang);
      return this.getName(mesure)?.[needsForm];
    },
  },
  mounted() {
    function sizeControl(entries) {
      const startScale = 0.75;

      entries.forEach((entry) => {
        const maxShift = 1 - startScale;
        const shift = entry.intersectionRatio < 0.7 ? entry.intersectionRatio : 0.7;
        const result = startScale + (shift * maxShift) / 0.7;
        entry.target.style.setProperty('--scaleSize', result);
        if (result < 1) {
          entry.target.classList.add('hide-controls');
        } else {
          entry.target.classList.remove('hide-controls');
        }
      });
    }
    this.observer = observeVisibilityOnPage(sizeControl);
    this.observer.observe(this.$el);
  },
  beforeUnmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
.aircraft-slider {
  --scaleSize: 1;
  position: relative;
  width: 100%;
  color: color(basic-light);

  @include wRule(
    height,
    (
      xl: 100vh,
      sm: 680,
    )
  );

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    position: relative;
    z-index: 1;
  }

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.6s ease;
    transform: scale(var(--scaleSize));

    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(19, 29, 45, 0) 40.96%,
        rgba(19, 29, 45, 0.6) 98.3%
      );
      z-index: 2;
    }
  }

  .panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    transition: opacity $ease-main;
  }

  &.hide-controls {
    .panel {
      opacity: 0;
    }
  }

  .controls {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen("sm") {
      justify-content: flex-start;
    }

    @include wRule(
      padding,
      (
        xl: 58 0,
        lg: 42 0,
        sm: 18 0,
      )
    );

    &::before {
      @include pseudo;

      bottom: 0;
      left: 0;
      width: 100%;
      background-color: color(basic-light);

      @include wRule(
        height,
        (
          xl: 4,
          lg: 4,
          sm: 3,
        )
      );
    }
  }

  .button {
    color: currentColor;
    cursor: pointer;
    transition: opacity $ease-main;

    @include wRule(
      height,
      (
        xl: 60,
        lg: 34,
        sm: 38,
      )
    );

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &.is-hide {
      opacity: 0;
      pointer-events: none;
    }

    .icon {
      height: 100%;
    }

    @include screen("sm", max) {
      display: none;
    }
  }

  ::v-deep(.title) {
    line-height: 1.2;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 20,
        sm: 28,
      )
    );
  }

  .info {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.6;

    @include screen("sm") {
      flex-wrap: wrap;
    }

    @include wRule(
      padding,
      (
        sm: 26 0 48,
      )
    );

    @include wRule(
      height,
      (
        xl: 186,
        lg: 146,
        sm: auto,
      )
    );

    .link {
      margin-left: auto;
      font-weight: 700;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 16,
          sm: 28,
        )
      );
    }
  }

  .features {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    @include screen("sm") {
      width: 100%;
      justify-content: space-between;
    }

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    @include wRule(
      margin-bottom,
      (
        sm: 38,
      )
    );
  }

  .feature {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      @include wRule(
        width,
        (
          xl: 32,
          lg: 30,
          sm: 32,
        )
      );

      @include wRule(
        height,
        (
          xl: 32,
          lg: 30,
          sm: 32,
        )
      );

      @include wRule(
        margin-right,
        (
          xl: 16,
          lg: 13,
          sm: 16,
        )
      );

      &_clock,
      &_cycle {
        @include wRule(
          width,
          (
            xl: 25,
            lg: 20,
            sm: 24,
          )
        );

        @include wRule(
          height,
          (
            xl: 25,
            lg: 20,
            sm: 24,
          )
        );

        @include wRule(
          margin-right,
          (
            xl: 19,
            lg: 19,
            sm: 20,
          )
        );
      }
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 60,
          lg: 57,
          sm: 10,
        )
      );
    }
  }
}
</style>
