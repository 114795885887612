export default (number, wordForms, lang) => {
  const n = number;
  let result = 0;
  const formsArray = wordForms;
  if (lang === 'en') {
    result = n !== 1 ? 1 : 0;
  }

  if (lang === 'ru') {
    const firstStep = n % 10 === 1 && n % 100 !== 11;
    const secondStep = n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20);
    // eslint-disable-next-line no-nested-ternary
    result = (firstStep ? 0 : (secondStep ? 1 : 2));
  }

  return formsArray[result];
};
