<template>
  <div
    class="container-wide"
    v-observe-visibility="{ callback: animation, once: true }"
  >
    <transition name="fade-down">
      <ul
        class="breadcrumbs"
        :class="{ 'is-dark': theme }"
        v-show="inView"
      >
        <li
          v-for="(item, index) in pathList"
          :key="index"
        >
          <component
            :is="item.path ? 'router-link' : 'span'"
            :to="item.path"
          >{{ item.name }}</component>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { animationMixin } from './mixins/animation';

export default {
  name: 'Breadcrumbs',
  mixins: [animationMixin],
  props: {
    aircraftName: {
      type: String,
    },
  },
  computed: {
    pathList() {
      const data = this.navigationData?.[this.$route.name];
      console.log(data);
      const tree = data?.tree;
      const end = [{
        path: undefined,
        name: data?.title || this?.aircraftName,
      }];
      const result = tree?.concat(end);
      return result;
    },
    navigationData() {
      return this.$store.getters.getNavigationData;
    },
    navColors() {
      return this.$store.getters.getNavigationColors;
    },
    theme() {
      return this.navColors[this.$route.name];
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  color: color(basic-light);
  line-height: 1.6;

  @include wRule(
    margin-bottom,
    (
      xl: 110,
      lg: 74,
      sm: 0,
    )
  );

  @include rule(
    display,
    (
      xl: flex,
      sm: none,
    )
  );
  @include wRule(
    padding-top,
    (
      xl: 180,
      lg: 118,
      sm: 0,
    )
  );
  @include wRule(
    font-size,
    (
      xl: 16,
      lg: 14,
      sm: 12,
    )
  );

  &.is-dark {
    color: color(basic);
  }

  li {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 13,
          lg: 13,
          sm: 13,
        )
      );

      &::after {
        display: block;
        content: "";
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1465 10.1924L15.0962 5.24263' stroke='%23999999'/%3E%3Cpath d='M15.0957 5.94971L10.146 0.99996' stroke='%23999999'/%3E%3Cpath d='M14 5.59619L0 5.59619' stroke='%23999999'/%3E%3C/svg%3E ");

        @include wRule(
          width,
          (
            xl: 16,
            lg: 15,
            sm: 10,
          )
        );
        @include wRule(
          height,
          (
            xl: 16,
            lg: 15,
            sm: 10,
          )
        );
        @include wRule(
          margin-left,
          (
            xl: 13,
            lg: 13,
            sm: 13,
          )
        );
      }
    }
  }

  span {
    display: inline-block;
    white-space: nowrap;
    cursor: default;
  }

  a {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;

    &::before {
      @include pseudo;

      font-size: inherit;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }
}
</style>
