<template>
  <div class="sale-tab-listing">
    <Loader v-if="contentLoading" />
    <template v-else>
      <AircraftSlider
        v-for="(aircraft, aircraftId) in aircrafts"
        :key="`${aircraft?.vehicle_type?.type_id}${aircraftId}`"
        :content="aircraft"
      />
    </template>
  </div>
</template>

<script>
import AircraftSlider from './AircraftSlider.vue';
import api from '../../../tools/api';
import Loader from '../../../components/Loader.vue';

export default {
  components: { AircraftSlider, Loader },
  name: 'SaleTabListing',
  props: {
    aircraftType: {
      type: String,
    },
  },
  data() {
    return {
      aircrafts: [],
      contentLoading: true,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getAircrafts() {
      const results = await api(
        'vehicles',
        true,
        `vehicle_type.type_id=${encodeURI(
          this.aircraftType?.type_id,
        )}&sale=true&_sort=display_order:ASC`,
      );
      this.contentLoading = !results.statusText === 'ok';
      this.aircrafts = results.data;
    },
  },
  watch: {
    lang() {
      this.getAircrafts();
    },
  },
  created() {
    this.getAircrafts();
  },
};
</script>
