<template>
  <div class="page-container">
    <PageSection class="centered sale-lead">
      <template #default>
        <Breadcrumbs />
        <div class="container">
          <div
            class="header"
            v-if="pageContent"
          >
            <h3
              class="title"
              v-inview-animate="{name: 'fade-down', duration: 500, delay: 500}"
            >{{pageContent?.title}}</h3>
            <p
              class="text"
              v-html="pageContent?.description"
              v-inview-animate="{name: 'fade-down', duration: 500, delay: 600}"
            ></p>
          </div>
        </div>

        <Tabs
          :tabs="pageContent?.tab"
          :container="true"
          v-if="pageContent?.tab"
          :initActive="0"
        >
          <template v-slot="{ activeTab }">
            <SaleTabListing
              class="listing"
              :key="activeTab?.label?.name"
              :aircraftType="activeTab?.vehicle_type"
            />
          </template>
        </Tabs>
      </template>
    </PageSection>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import PageSection from '../../components/PageSection.vue';
import Tabs from '../../components/Tabs/Tabs.vue';
import api from '../../tools/api';
import SaleTabListing from './components/SaleTabListing.vue';

export default {
  name: 'Sale',
  components: {
    Breadcrumbs,
    PageSection,
    Tabs,
    SaleTabListing,
  },
  data() {
    return {
      pageContent: {},
      aircrafts: [],
      contentLoading: true,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('sell', true);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
  },
  created() {
    this.getPageContent();
  },
  watch: {
    lang() {
      this.getPageContent();
    },
  },
};
</script>

<style lang="scss" scoped>
.sale-lead {
  @include wRule(
    padding,
    (
      xl: 0 0 70,
      lg: 0 0 20,
      sm: 192 0 40,
    )
  );

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      @include rule(
        line-height,
        (
          xl: 1.6,
          lg: 1.5,
          sm: 1.4,
        )
      );

      @include wRule(
        max-width,
        (
          xl: 840,
          lg: 585,
          sm: 100%,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 15,
            lg: 19,
            sm: 40,
          )
        );
      }
    }
  }

  .tabs {
    width: 100%;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 60,
          lg: 38,
          sm: 54,
        )
      );
    }

    ::v-deep(.navigation) {
      @include wRule(
        margin-bottom,
        (
          xl: 50,
          lg: 30,
          sm: 0,
        )
      );
    }

    ::v-deep(.aircraft-slider) {
      @include wRule(
        margin,
        (
          xl: 50 0,
          lg: 40 0,
          sm: 60 0,
        )
      );
    }
  }
}
</style>
